const environment = process.env.NODE_ENV || 'development';
const environmentConfig  = require(`./${environment}.js`);
// FIXME NODE_ENVは使えない
const ItemConfig = {
  apiForEstimatePrice: environmentConfig.estimate_pricing_url,
  itemConditionCode: {
    NEW: {code:'N10', name:'新品'},
    VERY_GOOD: {code:'U20', name:'非常に良い'},
    GOOD: {code:'U30', name:'良い'},
    ACCEPTABLE: {code:'U40', name:'キズや使用感あり'},
  },
  columnWidth :{
    itemXXS: {
      small: 62,
      medium: 90,
      large: 140,
      list: 124,
      row: 90,
    },
    itemXS: {
      small: 62,
      medium: 104,
      large: 164,
      list: 124,
      row: 100,
    },
    itemSM: {
      small: 104,
      medium: 124,
      large: 184,
      list: 144,
      row: 100,
    },
    itemMD: {
      small: 124,
      medium: 144,
      large: 204,
      list: 164,
      row: 140,
    },
    itemLG: {
      small: 124,
      medium: 164,
      large: 224,
      list: 184,
      row: 140,
    },
    itemXL: {
      small: 124,
      medium: 184,
      large: 244,
      list: 204,
      row: 140,
    },
  }
}
export default ItemConfig