const itemListConfig = {
    keywordList: [
        {
            genreId: 1,
            text: 'コミック'
        },
        {
            genreId: 2,
            text: '雑誌',
        },
        {
            genreId: 3,
            text: '新書',
        },
        {
            genreId: 4,
            text: '文庫',
        },
        {
            genreId: 5,
            text: '文芸',
        },
        {
            genreId: 6,
            text: 'ライトノベル・BL',
        },
        {
            genreId: 7,
            text: '絵本・こどもの本',
        },
        {
            genreId: 8,
            text: 'ビジネス',
        },
        {
            genreId: 9,
            text: 'IT',
        },
        {
            genreId: 10,
            text: '自然科学',
        },
        {
            genreId: 11,
            text: '教育・学習参考書',
        },
        {
            genreId: 12,
            text: '人文・社会科学',
        },
        {
            genreId: 13,
            text: '語学・辞典・事典',
        },
        {
            genreId: 14,
            text: '資格試験・検定',
        },

        {
            genreId: 15,
            text: 'アート・デザイン',
        },
        {
            genreId: 16,
            text: '趣味・実用',
        },
        {
            genreId: 17,
            text: 'エンターテインメント',
        },
        {
            genreId: 18,
            text: 'その他',
        },
    ],
    articleList: [
        {
            title: 'バリューブックスの送料について解説！査定額から使い方のポイントまで',
            id: 5895,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/souryounazeアイキャッチ-scaled.jpg',
        },
        {
            title: 'BOOK BUSにて「パタゴニア ブックフェア」を 開催中！',
            id: 11452,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/patagoniabookbus01-scaled.jpg',
        },
        {
            title: '【目標 500万円分！】あなたと本屋で贈る「ブック・プレゼント」プロジェクト',
            id: 11411,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/bookpresent-520x292.png',
        },
        {
            title: '北極冒険家・荻田泰永さんが手がける絵本『PIHOTEK ピヒュッティ-北極を風と歩く-』発売記念トークイベント開催！',
            id: 11368,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/DSC6680-1-520x292.jpg',
        },
        {
            title: '本だ！ねこだ！おまつりだ！「にゃんとこフェスタ」開催中',
            id: 11313,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%82%B5%E3%83%A0%E3%83%8D%E3%82%A4%E3%83%AB-520x292.jpg',
        },
        {
            title: 'ゆる言語学ラジオは「ラーメン二郎」でいつか「マフィア」になる？ “言語”の面白さを届けるコンテンツの裏側を直接聞いてみた！',
            id: 11257,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/300094492_572828041236145_7396248089810400795_n-520x292.jpg',
        },
        {
            title: '本だったノート – 古紙になるはずだった本からできたノート',
            id: 11189,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/hondattanote001-520x292.jpg',
        },
        {
            title: '負けず嫌いとインパクト |『B Corpハンドブック』日本語版まえがき 【文＝鳥居希】',
            id: 11147,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/HRM1350-520x292.jpg',
        },
        {
            title: '積み本を売って「ゆる言語学ラジオ」グッズを手に入れよう！',
            id: 11102,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/295987670_765563514487205_6169102135246835501_n-520x292.jpg',
        },
        {
            title: '「ゆる言語学ラジオ」がバリューブックスのアンバサダーになりました！',
            id: 11097,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%82%86%E3%82%8B%E8%A8%80%E8%AA%9E%E5%AD%A6%E3%83%A9%E3%82%B7%E3%82%99%E3%82%AA_%E3%83%A1%E3%82%A4%E3%83%B3%E3%83%92%E3%82%99%E3%82%B7%E3%82%99%E3%83%A5%E3%82%A2%E3%83%AB_%E6%A8%AA%E9%95%B7-520x292.png',
        },
        {
            title: '中川政七商店 × VALUE BOOKS コラボキャンペーン — 最大8,000円！本を売って、中川政七商店のクーポンをゲットしよう！',
            id: 11059,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/295844495_1078663312768740_912650983091517423_n-520x292.jpg',
        },
        {
            title: '本屋が描く世界 – VALUE BOOKS 創業15周年',
            id: 11033,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/15th_all-520x292.png',
        },
        {
            title: 'ブックバスが下北に常駐！？一緒に働くメンバーを募集します！',
            id: 10986,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/%E3%83%96%E3%83%83%E3%82%AF%E3%83%90%E3%82%B9_%E4%B8%80%E7%B7%92%E3%81%AB%E5%83%8D%E3%81%8F%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E3%82%92%E5%8B%9F%E9%9B%86%E3%81%97%E3%81%BE%E3%81%99-520x292.png',
        },
        {
            title: 'キャンペーンでもらえる！バリューブックスのポイント付与の流れ',
            id: 10932,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/point-520x292.jpg',
        },
        {
            title: '【撮影協力】映画『流浪の月』にてバリューブックスの本が使われました！',
            id: 10874,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/ruro_no1_poster_0214-1-520x292.jpg',
        },
        {
            title: '【撮影協力】映画『マイスモールランド』にてバリューブックスの本が使われました！',
            id: 10850,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/MSL_poster_fulls-520x292.jpg',
        },
        {
            title: '歴史をめくると、悩みがほどけていく — 納品書のウラ書き vol45',
            id: 10903,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/NLaPr3vf-DSC_0099-520x292.jpg',
        },
        {
            title: '本と音楽のフェス「 Lotus music ＆ book cafe ’22 」　ー 出演アーティストによる選書を公開します！ ー',
            id: 10792,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/nav_music-520x292.jpg',
        },
        {
            title: '「ブックバスを大学に呼べますか？」',
            id: 10757,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/unnamed-4-520x292.jpg',
        },
        {
            title: '【本と出会う場所】店は生活を彩る“表現の場” ／日常雑貨店「pace around」（長野県・御代田）',
            id: 10618,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/YOMIMONO_pace-around_750%C3%97450-520x292.jpg',
        },
        {
            title: '【本と出会う場所】過去と未来をつなぎ、街の日常を支える暮らしの本屋／「MINOU BOOKS」（福岡県・うきは市）',
            id: 10623,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/YOMIMONO_MINOU-BOOKS_750%C3%97450-520x292.jpg',
        },
        {
            title: '本屋が勧める、旬を味わう”お茶の定期便”',
            id: 10600,
            image: 'https://wcdn.valuebooks.jp/endpaper/upload/DSC_0175-520x292.jpg',
        },
    ],
    valuebooksList: [
        {
            "vs_catalog_id" : "VS0058940222",
            "title" : "【Carton Studioコラボ】旅するノート_01",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_01",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/zPZovaSP-01_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0063398016",
            "title": "本とダンボールだったノート",
            "title_for_url": "%E6%9C%AC%E3%81%A8%E3%83%80%E3%83%B3%E3%83%9C%E3%83%BC%E3%83%AB%E3%81%A0%E3%81%A3%E3%81%9F%E3%83%8E%E3%83%BC%E3%83%88",
            "author": "バリューブックス・パブリッシング",
            "image_url": [
                "https://wcdn.valuebooks.jp/endpaper/upload/IMG_4867.jpg"
            ],
            "base_price_in_tax": 880,
            "min_sell_price": 880,
            "min_sell_price_condition": "N10",
            "isbnCode10": "4910865020",
            "asinCode": "",
            "isbnCode13": "9784910865027",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0084586999",
            "title": "本だったノート",
            "title_for_url": "%E6%9C%AC%E3%81%A0%E3%81%A3%E3%81%9F%E3%83%8E%E3%83%BC%E3%83%88",
            "author": "バリューブックス・パブリッシング",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/hondatta_note.jpg"
            ],
            "base_price_in_tax": 880,
            "min_sell_price": 880,
            "min_sell_price_condition": "N10",
            "isbnCode10": "4910865012",
            "asinCode": "",
            "isbnCode13": "9784910865010",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065593253",
            "title": "【木の実とポストカード付き】世界のふしぎな木の実図鑑",
            "title_for_url": "%E3%80%90%E6%9C%A8%E3%81%AE%E5%AE%9F%E3%81%A8%E3%83%9D%E3%82%B9%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E4%BB%98%E3%81%8D%E3%80%91%E4%B8%96%E7%95%8C%E3%81%AE%E3%81%B5%E3%81%97%E3%81%8E%E3%81%AA%E6%9C%A8%E3%81%AE%E5%AE%9F%E5%9B%B3%E9%91%91",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/sekainofusigina_kinomiset.jpg"
            ],
            "base_price_in_tax": 3600,
            "min_sell_price": 3600,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058751619",
            "title": "二度と行けない（上田の）あの店で",
            "title_for_url": "%E4%BA%8C%E5%BA%A6%E3%81%A8%E8%A1%8C%E3%81%91%E3%81%AA%E3%81%84%EF%BC%88%E4%B8%8A%E7%94%B0%E3%81%AE%EF%BC%89%E3%81%82%E3%81%AE%E5%BA%97%E3%81%A7",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/nidoto_ueda.jpg"
            ],
            "base_price_in_tax": 500,
            "min_sell_price": 500,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065590487",
            "title": "「ちくわがうらがえる」記録集【オリジナルステッカー・刊行記念トークイベント動画つき】",
            "title_for_url": "%E3%80%8C%E3%81%A1%E3%81%8F%E3%82%8F%E3%81%8C%E3%81%86%E3%82%89%E3%81%8C%E3%81%88%E3%82%8B%E3%80%8D%E8%A8%98%E9%8C%B2%E9%9B%86%E3%80%90%E3%82%AA%E3%83%AA%E3%82%B8%E3%83%8A%E3%83%AB%E3%82%B9%E3%83%86%E3%83%83%E3%82%AB%E3%83%BC%E3%83%BB%E5%88%8A%E8%A1%8C...",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/chikuwagauragaeru.jpg"
            ],
            "base_price_in_tax": 1500,
            "min_sell_price": 1500,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065594687",
            "title": "本の切れはしで作る「ドライフラワーの花器」キット（アソートカラー）",
            "title_for_url": "%E6%9C%AC%E3%81%AE%E5%88%87%E3%82%8C%E3%81%AF%E3%81%97%E3%81%A7%E4%BD%9C%E3%82%8B%E3%80%8C%E3%83%89%E3%83%A9%E3%82%A4%E3%83%95%E3%83%A9%E3%83%AF%E3%83%BC%E3%81%AE%E8%8A%B1%E5%99%A8%E3%80%8D%E3%82%AD%E3%83%83%E3%83%88%EF%BC%88%E3%82%A2%E3%82%BD%E3%83%BC...",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/kamitaba_drykaki.jpg"
            ],
            "base_price_in_tax": 1980,
            "min_sell_price": 1980,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065599600",
            "title": "BOOKBUS マスキングテープ",
            "title_for_url": "BOOKBUS-%E3%83%9E%E3%82%B9%E3%82%AD%E3%83%B3%E3%82%B0%E3%83%86%E3%83%BC%E3%83%97",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/BOOKBUS_maste.jpg"
            ],
            "base_price_in_tax": 500,
            "min_sell_price": 500,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058752539",
            "title": "BOOKBUS トート",
            "title_for_url": "BOOKBUS-%E3%83%88%E3%83%BC%E3%83%88",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/endpaper/upload/bookbus_tote_202301.png"
            ],
            "base_price_in_tax": 2000,
            "min_sell_price": 2000,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065591176",
            "title": "NABOタンブラー",
            "title_for_url": "NABO%E3%82%BF%E3%83%B3%E3%83%96%E3%83%A9%E3%83%BC",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABO_tumbler.jpg"
            ],
            "base_price_in_tax": 3500,
            "min_sell_price": 3500,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065589737",
            "title": "NABOトートL（ナチュラル）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88L%EF%BC%88%E3%83%8A%E3%83%81%E3%83%A5%E3%83%A9%E3%83%AB%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_L_white.jpg"
            ],
            "base_price_in_tax": 1600,
            "min_sell_price": 1600,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058754096",
            "title": "NABOトートL（イエロー）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88L%EF%BC%88%E3%82%A4%E3%82%A8%E3%83%AD%E3%83%BC%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_L_yellow.jpg"
            ],
            "base_price_in_tax": 1600,
            "min_sell_price": 1600,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0065590496",
            "title": "NABOトートL（ブルー）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88L%EF%BC%88%E3%83%96%E3%83%AB%E3%83%BC%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_L_blue.jpg"
            ],
            "base_price_in_tax": 1600,
            "min_sell_price": 1600,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058753556",
            "title": "NABOトートL（ブラック）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88L%EF%BC%88%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_L_black.jpg"
            ],
            "base_price_in_tax": 1600,
            "min_sell_price": 1600,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058752159",
            "title": "NABOトートM（グレー）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88M%EF%BC%88%E3%82%B0%E3%83%AC%E3%83%BC%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_M_gray.jpg"
            ],
            "base_price_in_tax": 1200,
            "min_sell_price": 1200,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058754209",
            "title": "NABOトートM（イエロー）",
            "title_for_url": "NABO%E3%83%88%E3%83%BC%E3%83%88M%EF%BC%88%E3%82%A4%E3%82%A8%E3%83%AD%E3%83%BC%EF%BC%89",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABOtote_M_yellow.jpg"
            ],
            "base_price_in_tax": 1200,
            "min_sell_price": 1200,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id": "VS0058753574",
            "title": "NABOマルシェバッグ",
            "title_for_url": "NABO%E3%83%9E%E3%83%AB%E3%82%B7%E3%82%A7%E3%83%90%E3%83%83%E3%82%B0-",
            "author": "",
            "image_url": [
                "https://wcdn.valuebooks.jp/ws/goods/NABO_marche.jpg"
            ],
            "base_price_in_tax": 1200,
            "min_sell_price": 1200,
            "min_sell_price_condition": "N10",
            "isbnCode10": "",
            "asinCode": "",
            "isbnCode13": "",
            "isParentItemSet": false,
            "genreValuebooks": {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058940383",
            "title" : "【Carton Studioコラボ】旅するノート_02",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_02",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/059DiSCi-02_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058751789",
            "title" : "【Carton Studioコラボ】旅するノート_03",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_03",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/mBzhqvb5-03_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058740224",
            "title" : "【Carton Studioコラボ】旅するノート_04",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_04",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/GZn2X7v4-04_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0063318816",
            "title" : "【Carton Studioコラボ】旅するノート_05",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_05",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/1k4Dlpth-05_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058893854",
            "title" : "【Carton Studioコラボ】旅するノート_06",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_06",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/wemh7y1v-06_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058753963",
            "title" : "【Carton Studioコラボ】旅するノート_07",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_07",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/THLzOIUa-07_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058959822",
            "title" : "【Carton Studioコラボ】旅するノート_08",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_08",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/tvjN8brb-08_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058886942",
            "title" : "【Carton Studioコラボ】旅するノート_09",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_09",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/Mg0CoVlF-09_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058888153",
            "title" : "【Carton Studioコラボ】旅するノート_10",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_10",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/J4v6Yjva-10_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058888506",
            "title" : "【Carton Studioコラボ】旅するノート_11",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_11",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/ohlico48-11_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058786572",
            "title" : "【Carton Studioコラボ】旅するノート_12",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_12",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/mNVfu6HJ-12_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058729674",
            "title" : "【Carton Studioコラボ】旅するノート_13",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_13",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/68iPrZMb-13_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058940709",
            "title" : "【Carton Studioコラボ】旅するノート_14",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_14",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/Ubg33h9i-14_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058974186",
            "title" : "【Carton Studioコラボ】旅するノート_15",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_15",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/yXYNh5cw-15_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058810578",
            "title" : "【Carton Studioコラボ】旅するノート_16",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_16",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/pccXizfd-16_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058781045",
            "title" : "【Carton Studioコラボ】旅するノート_17",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_17",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/DaKrNcY8-17_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058991527",
            "title" : "【Carton Studioコラボ】旅するノート_18",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_18",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/Jz6UMmzY-18_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058703393",
            "title" : "【Carton Studioコラボ】旅するノート_19",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_19",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/fikjH4iB-19_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058916234",
            "title" : "【Carton Studioコラボ】旅するノート_20",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_20",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/MKBiVwJb-20_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058825127",
            "title" : "【Carton Studioコラボ】旅するノート_21",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_21",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/BlThMPA9-21_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058813308",
            "title" : "【Carton Studioコラボ】旅するノート_22",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_22",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/F5qOb1Jm-22_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058773213",
            "title" : "【Carton Studioコラボ】旅するノート_23",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_23",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/wKZQY7ax-23_front-1.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058699513",
            "title" : "【Carton Studioコラボ】旅するノート_24",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_24",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/9Ns4mXBY-24_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058771215",
            "title" : "【Carton Studioコラボ】旅するノート_25",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_25",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/NgxfJndV-25_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058690910",
            "title" : "【Carton Studioコラボ】旅するノート_26",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_26",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/oWnNWhKs-26_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058977484",
            "title" : "【Carton Studioコラボ】旅するノート_27",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_27",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/idq1yIYT-27_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058782053",
            "title" : "【Carton Studioコラボ】旅するノート_28",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_28",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/R01SWNFk-28_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058976209",
            "title" : "【Carton Studioコラボ】旅するノート_29",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_29",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/slxTZVV1-29_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0063384420",
            "title" : "【Carton Studioコラボ】旅するノート_30",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_30",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/1ARggcLJ-30_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0059002556",
            "title" : "【Carton Studioコラボ】旅するノート_31",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_31",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/IZJesTww-31_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058824386",
            "title" : "【Carton Studioコラボ】旅するノート_32",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_32",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/ZieAHh17-32_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058710205",
            "title" : "【Carton Studioコラボ】旅するノート_33",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_33",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/V3bRtiOa-33_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058762572",
            "title" : "【Carton Studioコラボ】旅するノート_34",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_34",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/haueLxrW-34_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058707487",
            "title" : "【Carton Studioコラボ】旅するノート_35",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_35",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/LsR9Lhue-35_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058712160",
            "title" : "【Carton Studioコラボ】旅するノート_36",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_36",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/E0n42hYe-36_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058797055",
            "title" : "【Carton Studioコラボ】旅するノート_37",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_37",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/8DtI4WvK-37_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0063336645",
            "title" : "【Carton Studioコラボ】旅するノート_38",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_38",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/UHYVgoFW-38_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0063336636",
            "title" : "【Carton Studioコラボ】旅するノート_39",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_39",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/KBQvgcon-39_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058753705",
            "title" : "【Carton Studioコラボ】旅するノート_40",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_40",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/tlzOJOhD-40_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        },
        {
            "vs_catalog_id" : "VS0058753802",
            "title" : "【Carton Studioコラボ】旅するノート_41",
            "title_for_url" : "%E3%80%90Carton%20Studio%E3%82%B3%E3%83%A9%E3%83%9C%E3%80%91%E6%97%85%E3%81%99%E3%82%8B%E3%83%8E%E3%83%BC%E3%83%88_41",
            "author" : "",
            "image_url" : [
                "https://wcdn.valuebooks.jp/endpaper/upload/rdgV8z9s-41_front.jpg"
            ],
            "base_price_in_tax" : "",
            "min_sell_price" : 1760,
            "min_sell_price_condition" : 1760,
            "isbnCode10" : "",
            "asinCode" : "",
            "isbnCode13" : "",
            "isParentItemSet" : "",
            "genreValuebooks" : {
                "18": "その他"
            }
        }
    ],
    'officialLibraryFolderIds': [
        { 1: 'e364e56350b470e',
            2: '7b650017b25d2fa', }// 本番用
            //2: '2064eae4367e497',}// ローカル確認用 ueda
    ],
}

export default itemListConfig;
