<template>
  <v-btn
    v-if="!is_item_selectable"
    @click.stop="audioPlayHandler"
    elevation="0"
    icon
    class="d-block ml-2"
    :class="(layout === 'column' || layout === 'small-column' || layout === 'row' || layout === 'fixed-column')? 'mt-2': 'mt-3'"
    dark
    style="background:linear-gradient(0.25turn, #0870c9, #0fb78a);"
    :width="icon_width"
    :height="icon_width"
  >
    <template v-if="$slots.icon">
      <slot name="icon" />
    </template>
    <v-icon v-else color="white" :size="icon_width - 4">
      {{ isAudioPlaying ? 'mdi-pause' : 'mdi-play' }}
    </v-icon>
  </v-btn>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex';
import audioController from './audio-controller.vue';

export default {
  components: {
    audioController
  },
  name: "audio-button",
  props: {
    layout: {
      type: String,
      required: true,
      default: 'column'
    },
    is_item_selectable: {
      type: Boolean,
      required: true,
      default: false
    },
    icon_width: {
      type: Number,
      required: false,
      default: 20
    },
    item: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      audio: null,
      isAudioPlaying: false,
      showController: false,
    }
  },
  methods: {
    async audioPlayHandler(event) {
      event.preventDefault();
      const vsCatalogID = this.item.vs_catalog_id;
      if(!vsCatalogID) return;
      this.isAudioPlaying = !this.isAudioPlaying;

      if (this.isAudioPlaying) {
        if(this.getAudioPlaying) {
          await this.pauseAudio();
        }
        if(this.getCatalogIdToPlayAudio && this.getCatalogIdToPlayAudio !== vsCatalogID) {
          this.removeAudio();
        }
        // 初回再生時のみコントローラーを表示する
        if(!this.getShowController) {
          this.setCatalogIdToPlayAudio(vsCatalogID);
          this.pushToAudioPlayHistory(this.createAudioPlayHistory());
          this.setShowController(true);
        }
        this.playAudio();
      } else {
        await this.pauseAudio();
      }
    },
    createAudioPlayHistory() {
      return {
        vsCatalogID: this.item.vs_catalog_id,
        title: this.item.title,
        imageURL: this.item.image_url[0],
        audioURL: this.item.audioUrl,
        titleForUrl: this.item.title_for_url, 
      }
    },
    removeAudio() {
      this.setShowController(false);
      this.setAudioPaused(false);
      this.setAudioPlaying(false);
    },
    ...mapMutations('audio', ['setShowController', 'setCatalogIdToPlayAudio', 'setAudioPlaying', 'setAudioPaused',]),
    ...mapActions('audio', ['playAudio', 'pauseAudio', 'pushToAudioPlayHistory']),
  },
  computed: {
    ...mapGetters('audio', ['getShowController', 'getCatalogIdToPlayAudio', 'getAudioPlaying',]),
  },
  watch: {
    getAudioPlaying: {
      handler: function (val) {
        if (this.getCatalogIdToPlayAudio === this.item.vs_catalog_id) {
          this.isAudioPlaying = val;
        } else {
          this.isAudioPlaying = false;
        }
      },
      immediate: true
    },
    getCatalogIdToPlayAudio: {
      handler: function (newVal, oldVal) {
        if (oldVal === this.item.vs_catalog_id && newVal !== oldVal) {
          this.isAudioPlaying = false;
        }
      },
      immediate: true
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-btn__content {
  max-height:100% !important;
}
</style>