<template>
  <v-btn
    v-if="item.min_sell_price > 0"
    @click.stop="addToCartQuickly"
    elevation="4"
    class="ma-0 px-0 rounded"
    :class="getClassNameAddToCartQuickly"
    :small="!is_price_label_size_x_small"
    :x-small="is_price_label_size_x_small"
    color="#a0330a"
    label
    text-color="white"
    style="position: absolute; right: -3px; bottom: -3px;"
    :style="getLeftPositionByListForAddToCart"
    width="max-content"
    :loading="isLoading"
  >
    <img alt="addToCart" class="icon-price ml-2"
         :class="is_price_label_size_x_small ? 'x-small' : null"
         :src="`${cdnUrl}/assets/images/cart/icon_cartadd.svg`">
    <span
      class="py-0 px-1 d-flex align-center price white--text font-weight-bold"
      :style="smAndDown ? 'padding-top: 1px !important' : null">
      {{ item.min_sell_price | addCommaSeparator }}円
    </span>
  </v-btn>
</template>

<script>
import BadgeCartItemsCount from "../../container/badge-cart-items-count.vue";
import { mapMutations } from 'vuex';

export default {
  name: "add-to-cart-button",
  props: {
    item: {type: Object, required: false, default: null},
    layout: {type: String, required: false, default: 'column'},
    visible_addCart_btn: {type: Boolean, required: false, default: true},
    is_price_label_size_x_small: {type: Boolean, required: false, default: false},
    is_cart_update: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      conditionCodeType: VueConfig.conditionCode,
      noImageSrc: VueConfig.cdn_url + '/assets/images/common/no_image_bg_straight.svg',
      isLoading: false
    }
  },
  computed: {
    getClassNameAddToCartQuickly() {
      return "price-by-sell-buy-" + this.layout
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    ...mapMutations('item', [
      'setSnackbar',
      'setSnackbarAddCart',
    ]),
    async addToCartQuickly(event) {
      if(this.isLoading) {
        event.preventDefault();
        return;
      }
      this.isLoading = true;
      event.preventDefault();
      const url = '/api/cart/addCart'

      let vsCatalogID;
      let genpin;
      if (this.item.isParentItemSet && Array.isArray(this.item.childItemSet) && 0 < this.item.childItemSet.length) {
        vsCatalogID = this.item.childItemSet[0];

        if (this.item.min_sell_price_condition === this.conditionCodeType.ACCEPTABLE) {
          const childComicset = (await axios.get("/api/item/descriptions", {
            params: {vs_catalog_ids: [vsCatalogID]}
          })).data.items[0];

          const selectU40StockAvoidingHead = (stocksAlreadyRotated) => {
            return stocksAlreadyRotated
              ? stocksAlreadyRotated.find(stock => (stock.conditionCode === this.conditionCodeType.ACCEPTABLE))
              : null;
          }
          genpin = selectU40StockAvoidingHead(childComicset.genpinList);
        }
      } else {
        vsCatalogID = this.item.vs_catalog_id;
        if (this.item.min_sell_price_condition === this.conditionCodeType.ACCEPTABLE) {
          genpin = this.selectedGenpinItemByCondition(this.item.min_sell_price_condition);
        }
      }

      let genpin_id = null;
      if (this.item.min_sell_price_condition === this.conditionCodeType.ACCEPTABLE) {
        genpin_id = (genpin && genpin.genpinId) ? genpin.genpinId : null;
        if (!genpin_id) {
          this.setSnackbar({
            color: "#009098",
            icon: "mdi-alert-circle",
            position: "right",
            text: "既にカートに追加されているか、他のお客様が購入されたため、在庫を確保できませんでした",
            timeout: 3000,
            visible: true
          });
          this.isLoading = false;
          return false
        }
      }

      const response = await axios.post(url, {
        "vs_catalog_id": vsCatalogID,
        "condition": this.item.min_sell_price_condition,
        "genpin_id": genpin_id,
        "price": this.item.min_sell_price,
        "va_shoshi_id": null
      }).catch((e) => {
        // FIXME
        this.setSnackbar({
          color: "#980000",
          icon: "mdi-check",
          position: "right",
          text: "申し訳ありません。システムエラーによりカートに追加できませんでした。再度お試しください。",
          timeout: 1000,
          visible: true
        });
        this.isLoading = false;
      });
      if (response && response.data.numberOfItems !== null) {
        BadgeCartItemsCount.methods.badge_cart_items_count(response.data.numberOfItems);
        this.setSnackbarAddCart({
          color: 'white',
          icon: "mdi-check",
          mode: "multi-line",
          position: "center",
          text: "カートに商品が追加されました",
          timeout: 5000,
          visible: true,
          itemTitle: this.item.title,
          itemPrice: this.item.min_sell_price,
          itemImageUrl: this.item.image_url[0] ? this.item.image_url[0] : this.noImageSrc,
          itemConditionName: this.getConditionNameByAddToCart(),
        });
        if (this.is_cart_update) {
          // カートの画面にあるダイレクトカートボタンが押された場合、カートを更新する必要がある
          // 現状recommend-searchにあるvb-itemが対象となる cart-main内のコンポーネントのみ実行可能
          this.$store.dispatch('retrieveCartItems');
        }
        this.isLoading = false;
      } else {
        this.setSnackbar({
          color: "#009098",
          icon: "mdi-alert-circle",
          position: "right",
          text: "既にカートに追加されているか、他のお客様が購入されたため、変更できませんでした",
          timeout: 5000,
          visible: true
        });
        this.isLoading = false;
      }
    },
    getLeftPositionByListForAddToCart() {
      if (this.layout === 'list' && this.item.min_sell_price) {
        const offset = this.item.min_sell_price.toString().length > 3 ? 88 : 74;
        let style = `left: ${this.getColumnWidth(this.layout) - offset}px;`
        if (this.$vuetify.breakpoint.name === 'xs') {
          style += `bottom: 70px;`
        }
        return style
      }
    },
    getConditionNameByAddToCart() {
      switch (this.item.min_sell_price_condition) {
        case this.conditionCodeType.NEW:
          return '新品'
        case this.conditionCodeType.VERYGOOD:
          return '非常に良い'
        case this.conditionCodeType.GOOD:
          return '良い'
        case this.conditionCodeType.ACCEPTABLE:
          return 'キズや使用感あり'
      }
    },
    selectedGenpinItemByCondition(condition_code) {
      return this.item.genpinList ? this.item.genpinList.find(genpin => (genpin.conditionCode === condition_code)) : null;
    },
  }
}
</script>

<style scoped lang="scss">
.v-btn:hover::before {
  opacity: 0.3 !important;
}
.v-btn--loading {
  pointer-events: unset;
}
.price {
  margin-top: 1px;
}
.icon-sell-price {
  line-height: 0;
}
::v-deep {
  .v-progress-circular {
    width: 12px !important;
    height: 12px !important;
    .v-progress-circular__overlay {
     color: #FFF;
    }
  }
}

</style>