<template>
  <v-snackbar
    v-model="changeShowController"
    flat
    timeout="-1"
    style="z-index: 5"
    :style="showMobileLayout ? null : 'justify-content:flex-start !important;'"
    :width="showMobileLayout ? '100%' : '400'"
    height="124"
    elevation="0"
  >
    <v-card style="background:linear-gradient(0.25turn, #0870c9, #0fb78a);"
            height="124"
            flat
            elevation="0"
    >
      <v-btn plain @click="removeAudio" style="position:absolute;top:0;left:calc(100% - 48px);">
        <v-icon color="white" width="12" height="12">mdi-close</v-icon>
      </v-btn>
      <v-row dense no-gutters class="pa-4 ma-auto fill-height">
        <v-col cols="auto" class="mr-2">
          <a :href="itemDetailUrl" target="_blank">
            <v-img contain
              :src="audioImage"
              max-width="38" max-height="48" :alt="audioTitle"></v-img>
          </a>
        </v-col>
        <v-col cols="10" class="white--text d-flex align-center flex-column justify-center">
          <v-card-title class="text-body-2 px-0 py-1 pt-0 d-block text-truncate" style="width:100%;">{{ audioTitle }}</v-card-title>
          <v-card flat color="transparent" class="d-flex px-4 justify-space-between" width="100%">
            <v-icon @click="goToPreviousAudio" :disabled="!hasPreviousTruck" color="white">mdi-skip-previous</v-icon>
            <v-icon @click="goToNextAudio" :disabled="!hasNextTruck" color="white">mdi-skip-next</v-icon>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center pt-2">
          <audio controlslist="nodownload" ref="audio" id="audioController" controls style="width:100%;height:100%;max-width:100%;">
            <source :src="getAudioURL" type="audio/wav">
          </audio>
        </v-col>
      </v-row>
    </v-card>
  </v-snackbar>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
export default {
  name: "audio-controller",
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      isAudioPlaying: false,
      customBreakPoint: 400,
    }
  },
  methods: {
    ...mapMutations('audio', ['setShowController', 'setAudioPaused', 'setAudioElement', 'setCatalogIdToPlayAudio']),
    ...mapActions('audio', ['playAudio', 'pauseAudio']),
    removeAudio() {
      this.setShowController(false);
      this.pauseAudio();
      this.setAudioPaused(false);
    },
    async goToNextAudio() {
      if(this.hasNextTruck) {
        if(this.getAudioPlaying) {
          await this.pauseAudio();
        }

        // remove the current audio element
        this.removeAudio();
        this.setCatalogIdToPlayAudio(this.getNextAudioItem.vsCatalogID);
        if(!this.getShowController) {
          this.setShowController(true);
        }

        this.playAudio();
      }
    },
    async goToPreviousAudio() {
      if(this.hasPreviousTruck) {
        if(this.getAudioPlaying) {
          await this.pauseAudio();
        }

        // remove the current audio element
        this.removeAudio();
        this.setCatalogIdToPlayAudio(this.getPreviousAudioItem.vsCatalogID);
        if(!this.getShowController) {
          this.setShowController(true);
        }
        
        this.playAudio();
      }
    },
  },
  computed: {
    ...mapGetters('audio', [
      'getShowController',
      'getAudioURL',
      'getAudioItem',
      'getCatalogIdToPlayAudio',
      'getCurrentAudioIndex',
      'getPlayListLength',
      'getPreviousAudioItem',
      'getNextAudioItem',
      'getAudioPlaying',
    ]),
    changeShowController: {
      get() {
        return this.getShowController
      },
      set() {
        this.setShowController(!this.getShowController);
      }
    },
    showMobileLayout() {
      return this.$vuetify.breakpoint.width < this.customBreakPoint;
    },
    audioTitle() {
      if(!this.getAudioItem) return null;
      return this.getAudioItem.title;
    },
    audioImage() {
      if(!this.getAudioItem) return null;
      return this.getAudioItem.imageURL;
    },
    itemDetailUrl() {
      if(!this.getAudioItem) return null;
      return "/" + this.getAudioItem.titleForUrl + "/bp/" + this.getCatalogIdToPlayAudio;
    },
    hasPreviousTruck() {
      return !(this.getCurrentAudioIndex === 0 || this.getCurrentAudioIndex === -1);
    },
    hasNextTruck() {
      return this.getCurrentAudioIndex !== this.getPlayListLength - 1;
    },
  },
  mounted() {
    this.$store.commit('audio/setAudioElement', this.$refs.audio);
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-snack__wrapper {
    background-color: transparent !important;
    box-shadow: none !important;
    flex-wrap: wrap;
    margin: 4px;
    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: 100%;
    }
    @media screen and (min-width: 320px) {
      min-width: auto;
    }
  }

  .v-snack__content {
    padding: 0;
    width: 100%;
  }
}

</style>
