<template>
  <v-btn
    v-if="!is_item_selectable"
    @click.stop.prevent="toggleFavorite"
    elevation="0"
    icon
    class="d-block ml-auto "
    :class="(layout === 'column' || layout === 'small-column' || layout === 'row' || layout === 'fixed-column')? 'dense mr-0 mt-0': 'mr-2 mt-2'"
    dark
    color="success"
  >
    <template v-if="isFavorite">
      <v-img aspect-ratio="1" contain
             :max-width="icon_width"
             :src="cdnUrl + '/assets/images/item/btn-bookmark-on.svg'"/>
    </template>
    <template v-else>
      <v-img aspect-ratio="1" contain
             :max-width="icon_width"
             :src="cdnUrl + '/assets/images/item/btn-bookmark-outline.svg'"/>
    </template>
  </v-btn>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
export default {
  name: "add-to-favorite-button",
  props: {
    layout: {
      type: String,
      required: true
    },
    is_item_selectable: {
      type: Boolean,
      required: true
    },
    icon_width: {
      type: Number,
      required: false,
      default: '20'
    },
    vs_catalog_id: {
      type: String,
      required: true
    },
    is_favorite: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      localIsFavorite: this.is_favorite
    }
  },
  methods: {
    ...mapMutations('favorite', ['setIsRequesting', 'setStateToLoginModal']),
    ...mapActions('favorite', ['pushToFavoriteStore', 'removeFromFavoriteStore', 'createRequestConfig', 'processRequestQueue']),
    async toggleFavorite() {
      if (!this.$customerId) {
        // 共通のログインモーダルを表示する
        this.setStateToLoginModal({active: true, is_stop_event: true});
        return false;
      }
      this.isFavorite = !this.isFavorite;
      // キューにリクエストを追加
      this.createRequestConfig({isFavorite: this.isFavorite, vsCatalogID: this.vs_catalog_id});
      // リクエストがまだ処理中でなければ処理を開始
      if (!this.getIsRequesting) {
        await this.processRequestQueue();
      }
    },
  },
  computed: {
    ...mapGetters('favorite', ['containedInFavoriteStore', 'getIsRequesting', 'getPostQueue', 'getDeleteQueue']),
    isFavorite: {
      get() {
        return this.localIsFavorite;
      },
      set(value) {
        this.localIsFavorite = value;
      }
    }
  },
  watch: {
    getIsRequesting: {
      handler: function (val) {
        // リクエストがまだ処理中でなければキューにあるリクエストを処理
        if (!val && (this.getPostQueue.length || this.getDeleteQueue.length)) {
          this.processRequestQueue();
        }
      },
      immediate: true
    },
    is_favorite(value) {
      this.localIsFavorite = value;
    }
  }
}
</script>

<style scoped lang="scss">

</style>