const campaignConfig = {
  immediateTransferCampaign: [
    {
      start_date: '2024-12-16 00:00:00',
      end_date: '2024-12-31 23:59:59',
    },
    {
      start_date: '2025-01-01 00:00:00',
      end_date: '2025-01-31 23:59:59',
    },
    {
      start_date: '2025-02-01 00:00:00',
      end_date: '2025-02-28 23:59:59',
    }
  ],
  low_price_campaign: {
    start_date: '2023-03-01 00:00:00',
    end_date: '2999-12-31 23:59:59' // 暫定;終了時期未定
  },
  christmas_campaign: {
    start_date: '2024-12-18 00:00:00',
    end_date: '2024-12-26 23:59:59'
  }
}

export default campaignConfig;