/**
 * 開発環境で使用する環境変数
 * npm run dev/watch実行環境で使用される定義
 */
const environmentConfig = {
    // 指定本棚スキャン用のスキャン結果DBキー
    'SCAN_FILE_ID_KEY1': '4694fc8f-7d27-470b-b1b2-1b1bd0f73b0a_20210121173520.jpg',
    'SCAN_FILE_ID_KEY2': '887059ef-4dbb-4179-9faa-db1a7c815e2d_20210122101246.jpg',
    'SCAN_FILE_ID_KEY3': '0ffecb4c-bb9a-4982-abce-f3a4ddc6b6f4_20210122101407.jpg',
    'SCAN_FILE_ID_KEY4': 'b0bec4a7-dfd2-44cd-8413-c219c7c8b393_20210122101517.jpg',
    'SCAN_FILE_ID_KEY5': 'c79d15d3-c4dd-4f76-91d8-6a8c47e696fe_20210122101700.jpg',
    'SCAN_FILE_ID_KEY6': '126ca5ca-8be7-4846-8aec-e1787c77c936_20210122101749.jpg',
    'SCAN_FILE_ID_KEY7': '4c2b7ab6-b559-4197-86e8-74397054602a_20210122101828.jpg',
    'estimate_pricing_url':'https://estimatedev.valuebooks.jp/pricing',
    // recaptchaのsiteKey設定
    'recaptcha_site_key': '6LenOJYmAAAAAK5vD2bAXVBziPV2poaolqhaUBFV',
    // 閲覧履歴の表示件数
    'max_browsing_history_display_count':60,
}

export default environmentConfig
