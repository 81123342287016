<script>
    export default {
        name: "badge-cart-items-count.vue",
        methods:{
            badge_cart_items_count: function ($val) {
              const badgeParentElement = document.querySelector('#badge-cart-items');
              const badgeElement = document.querySelector('#badge-cart-items .v-badge__badge');
              if (badgeElement) {
                if ($val !== 0) {
                  badgeParentElement.classList.remove('no-cnt');
                  badgeElement.innerHTML = $val;
                } else {
                  badgeParentElement.classList.add('no-cnt');
                  badgeElement.innerHTML = '';
                }
              }
            },
        },
        render: function() {
            return this;
        }
    }
</script>
